import React, { useState, useEffect, useContext } from 'react';
import Persona from "../components/Persona";
import Post from '../components/Post.js';
import MyBunch from '../components/Header/MyBunch';

import { GlobalContext } from '../components/context/PersonaPostContext/GlobalContext';
import { useMyBunch } from '../components/context/PersonaPostContext/useGlobal';
import { usePost } from '../components/context/PersonaPostContext/useGlobal';

function Bunch() {

  const { setBunchId } = useContext(GlobalContext);
  const { myBunchDetails, loading: loadingMyBunch, error: errorMyBunch } = useMyBunch();

  const { postDetails, loading: loadingPost, error: errorPost } = usePost();
  const { setPostId } = useContext(GlobalContext);

  useEffect(() => {
    setBunchId(1);
    setPostId(0);
  }, [setPostId]);

  return (
    <div className="">
      <div className='min-h-screen'>
        <div className="grid grid-cols-12">
          <div className="ColClass1">
          </div>

          <div className="ColClass2">
            <div className='leftColHeader'>
              <div className='flex items-center justify-center mb-[10px]'>
                <MyBunch />
              </div>

              <div>
                {myBunchDetails.map((persona, index) => (
                  <Persona key={index} persona={persona} />
                ))}
              </div>
            </div>
          </div>

          <div className="ColClass3">
            <div className='flex flex-col gap-[5px] lg:gap-[10px]'>
              {postDetails.map((post, index) => (
                <Post key={index} post={post} />
              ))}
            </div>
          </div>

          <div className="ColClass4">
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bunch;
