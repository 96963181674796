import React, { useState, useEffect, useContext } from 'react';
import Persona from "../components/Persona";
import { usePersona } from '../components/context/PersonaPostContext/useGlobal';
import { GlobalContext } from '../components/context/PersonaPostContext/GlobalContext';

function News() {

  const { personaDetails, loading: loadingPersona, error: errorPersona } = usePersona();
  const { setPersonaId } = useContext(GlobalContext);

  useEffect(() => {
    setPersonaId(0);
  }, [setPersonaId]);

  return (
    <div className='min-h-screen flex flex-col gap-[5px]'>
      {personaDetails.map((persona, index) => (
        <Persona key={index} persona={persona} />
      ))}
    </div>
  );
}

export default News;

