import React, { useState, useEffect } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

import axiosInstance from "../../utils/axiosInstance";
import { useAuth } from '../context/AuthContext/AuthContext';

function Google() {
    const { login } = useAuth();
    //console.log("ID", process.env.REACT_APP_GOOGLE_API_KEY);
   // console.log("Google Client ID:", process.env.GOOGLE_CLIENT_ID);
    
    
    const handleSuccess = (response) => {
        const credential = response.credential;
        const decoded = jwtDecode(credential); 

        // console.log('User Info:', decoded);
        // console.log(`Hello, ${decoded.name}\nEmail: ${decoded.email}`);

        googleLoginFn(decoded.email);
    };

    const handleFailure = (error) => {
        console.error('Login Failed:', error);
    };

    const googleLoginFn = async (email) => {
        let prmData = {
            email: email,
        };
        try {
            const response = await axiosInstance.post('User/oAuthLogin', prmData);
            const token = response.data.token;
            localStorage.setItem('token', token);
            const userResponse = await axiosInstance.get('user/userData');
            const userDetails = userResponse.data;
            login(token, userDetails);
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    return (
        <div className="registrationCard">
            {/* <GoogleOAuthProvider clientId="421756867538-j0u7v4he4hojeb98q1o2f2h0kcscgi2a.apps.googleusercontent.com"> */}
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_API_KEY}>
                <div>
                    <GoogleLogin
                        onSuccess={handleSuccess}
                        onError={handleFailure}
                    />
                </div>
            </GoogleOAuthProvider>
        </div>
    );
}

export default Google;
