import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { GlobalContext } from '../components/context/PersonaPostContext/GlobalContext';
import { usePersona, usePostOfPersona } from '../components/context/PersonaPostContext/useGlobal';
import Post from '../components/Post.js';
import BubbleChart from '../components/BubbleChart';
import BubbleChartBig from '../components/BubbleChartBig';
import InTheNews from '../components/InTheNews';

import unratedBlack from '../asset/unrated_black.svg';
import unratedWhite from '../asset/unrated_white.svg';
import vectorBlack from '../asset/drpDown/vector_black.svg';
import vectorWhite from '../asset/drpDown/vector_white.svg';

function PersonaDetails() {
    // const { personaId } = useParams();
    const location = useLocation();
    const personaId = location.state?.id;

    const { personaDetails, loading: loadingPersona, error: errorPersona } = usePersona();
    const { postDetailsOfPersona, loading: loadingPostOfPersona, error: errorPostOfPersona } = usePostOfPersona();
    const { setPersonaId, setPostOfPersonaId } = useContext(GlobalContext);
    const [showPlot, setShowPlot] = useState(true);
    const [showAllPosts, setShowAllPosts] = useState(false);

    const handleShowAll = () => {
        setShowAllPosts(prevState => !prevState);
        if (personaDetails.length > 0) {
            setPostOfPersonaId(personaDetails[0].persona_unique_id);
        }
    }

    useEffect(() => {
        setPersonaId(personaId);
    }, [setPersonaId]);

    return (
        <div className="grid grid-cols-12">
            <div className="ColClass1">
            </div>
            <div className="ColClass2">
                <InTheNews />
            </div>
            <div className="ColClass3">
                <div className='flex justify-center'>
                    {personaDetails.length > 0 &&
                        <div className='w-full flex flex-col gap-10 bg-white dark:bg-black'>
                            <div className='flex justify-center gap-3 my-[10px]'>
                                <img src={`${process.env.REACT_APP_IMAGE_URL}${personaDetails[0].persona_image}`} alt="..." className='w-[40px] h-[60px]' />
                                <div className='flex flex-col gap-1'>
                                    <h3 className='font-400'>{personaDetails[0].persona_name} </h3>
                                    <h5 className='font-300 max-w-[174px]'>{personaDetails[0].persona_description}</h5>
                                </div>
                            </div>
                            <div className='w-fit flex justify-center items-center m-auto rounded-[5px] gap-8' style={{ borderBottom: '0.5px solid var(--default-border, rgba(0, 0, 0, 0.10))' }}>
                                <div>
                                    {personaDetails[0].self == null
                                        ?
                                        <div>
                                            <img src={unratedWhite} alt="" className="darkMode" />
                                            <img src={unratedBlack} alt="" className="lightMode" />
                                        </div>
                                        :
                                        <BubbleChart data={personaDetails[0].data} type="personaDetails" />
                                    }
                                </div>
                                <div className='flex flex-col gap-[13px]'>
                                    <div>
                                        <h3 className='font-100'>Probably<span className='font-900'>: {personaDetails[0].vote_type}</span> </h3>
                                    </div>
                                    <div>
                                        <h5 className='font-300'>Q Score:</h5>
                                        {personaDetails[0].self == null
                                            ?
                                            <h5 className='font-700'>Be the FIRST to Vote</h5>
                                            :
                                            <h5 className='font-300'>{personaDetails[0].self}, {personaDetails[0].society}</h5>
                                        }
                                    </div>
                                    <div>
                                        {personaDetails[0].noofposts > 0 &&
                                            <h5 className='font-300'>Based on {personaDetails[0].noofposts} posts</h5>
                                        }
                                    </div>
                                </div>
                            </div>
                            {personaDetails[0].scatterPlotData != undefined &&
                                <div id='scatterPlot' className='flex flex-col gap-[10px] w-[358px] m-auto'>
                                    <div className='mx-2'>
                                        {!showPlot &&
                                            <button className='btnGray' onClick={() => setShowPlot(true)} >Show scatter plot</button>
                                        }
                                        {showPlot &&
                                            <button className='btnGray' onClick={() => setShowPlot(false)} >Hide scatter plot</button>
                                        }
                                    </div>
                                    {showPlot &&
                                        <div>
                                            <BubbleChartBig data={personaDetails[0].scatterPlotData} />
                                        </div>
                                    }
                                    <h2 className='font-600 text-center'>{personaDetails[0].noofposts} Posts</h2>
                                </div>
                            }
                            {personaDetails[0].scatterPlotData != undefined &&
                                <div className='bg-[#E5E5EF] w-[92px] h-[24px] rounded-[5px] text-[12px] font-400 flex justify-evenly items-center m-auto' onClick={handleShowAll} >
                                    All Posts
                                    <img src={vectorBlack} alt="" className="lightMode" />
                                    <img src={vectorWhite} alt="" className="darkMode" />
                                </div>
                            }

                            {showAllPosts ? (
                                <div className="flex flex-col gap-1">
                                    {postDetailsOfPersona.map((post) => (
                                        <Post key={post.id} post={post} />
                                    ))}
                                </div>
                            ) : null}
                        </div>
                    }
                </div>
            </div>
            <div className="ColClass4">
            </div>
        </div>

    );
}

export default PersonaDetails;
