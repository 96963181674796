import React, { useState, useRef, useEffect } from 'react';
import { useMixin } from '../context/MixinContext/useMixin';
import { useNavigate } from 'react-router-dom';

import starBlack from '../../asset/drpDown/star_black.svg';
import starWhite from '../../asset/drpDown/star_white.svg';
import chevronDownBlack from '../../asset/drpDown/vector_black.svg';
import chevronDownWhite from '../../asset/drpDown/vector_white.svg';

import clapBlack from '../../asset/drpDownContent/clap_black.svg';
import clapWhite from '../../asset/drpDownContent/clap_white.svg';

import rocketBlack from '../../asset/drpDownContent/rocket_black.svg';
import rocketWhite from '../../asset/drpDownContent/rocket_white.svg';

import runBlack from '../../asset/drpDownContent/run_black.svg';
import runWhite from '../../asset/drpDownContent/run_white.svg';

import tieSmallBlack from '../../asset/drpDownContent/tie_small_black.svg';
import tieSmallWhite from '../../asset/drpDownContent/tie_small_white.svg';

function TopPost() {
    const drpDownRef = useRef(null);
    const navigate = useNavigate();
    const [showDrpDown, setShowDrpDown] = useState(false);
    const { showPersona, setShowPersona } = useMixin();

    const toggleDrpDownPopup = () => {
        setShowDrpDown(!showDrpDown);
    };
    const toggleShowPersonaPopup = () => {
        navigate('in_the_news');
        toggleDrpDownPopup();
    };

    const filterPosts = () => {
        navigate('filtered_post');
        toggleDrpDownPopup();
    };

    const handleClickOutside = (event) => {
        if (drpDownRef.current && !drpDownRef.current.contains(event.target)) {
            setShowDrpDown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='md:relative' ref={drpDownRef}>
            <div className='headerDrpDown h-[24px] px-[5px] flex items-center cursor-pointer hover:bg-[#E5E5EF] dark:hover:bg-[#000]' onClick={toggleDrpDownPopup}>
                <div>
                    <img src={starBlack} alt="" className="lightMode" />
                    <img src={starWhite} alt="" className="darkMode" />
                </div>
                <div className='flex items-baseline px-[10px]'>
                    <h3 className='font-400'>Today’s top posts</h3>
                    <div className='pl-[10px]'>
                        <img src={chevronDownBlack} alt="" className="lightMode" />
                        <img src={chevronDownWhite} alt="" className="darkMode" />
                    </div>
                </div>
            </div>
            <div className=''>
                {showDrpDown &&
                    <div className='dialogClass absolute left-0 right-0 md:left-1/2 md:transform md:-translate-x-1/2'>
                        <div className='flex items-center gap-1' onClick={filterPosts}>
                            <img src={runBlack} alt="run" className='lightMode' />
                            <img src={runWhite} alt="run" className='darkMode' />
                            <h3 className='font-400'>Active now</h3>
                        </div>
                        <div className='flex items-center gap-1' onClick={filterPosts}>
                            <img src={rocketBlack} alt="rocket" className='lightMode' />
                            <img src={rocketWhite} alt="rocket" className='darkMode' />
                            <h3 className='font-400'>Week's top posts</h3>
                        </div>
                        <div className='flex items-center gap-1' onClick={filterPosts}>
                            <img src={clapBlack} alt="clap" className='lightMode' />
                            <img src={clapWhite} alt="clap" className='darkMode' />
                            <h3 className='font-400'>Month's top posts</h3>
                        </div>
                        <div className='flex items-center gap-1 md:hidden' onClick={toggleShowPersonaPopup} >
                            <img src={tieSmallBlack} alt="tieSmallBlack" className='lightMode' />
                            <img src={tieSmallWhite} alt="tieSmallBlack" className='darkMode' />
                            <h3 className='font-400'>In the news</h3>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default TopPost;
