import React, { useState, useRef, useEffect } from 'react';
import HoverIcon from "../../utils/HoverIcon";
import { useNavigate } from 'react-router-dom';
import AddPost from '../AddPost';

import pencilBlack from '../../asset/header/pencil_black.svg';
import pencilWhite from '../../asset/header/pencil_white.svg';
import pencilBlue from '../../asset/header/pencil_blue.svg';
import tieBlack from '../../asset/header/tie_black.svg';
import tieWhite from '../../asset/header/tie_white.svg';
import tieBlue from '../../asset/header/tie_blue.svg';

import gasBlack from '../../asset/header/gas_black.svg';
import gasWhite from '../../asset/header/gas_white.svg';
import gasYellow from '../../asset/header/gas_yellow.svg';

function MobileFooter() {

    const navigate = useNavigate();

    const buyVotesRef = useRef(null);
    const addPostRef = useRef(null);

    const [showBuyVotes, setShowBuyVotes] = useState(false);
    const [showAddPost, setShowAddPost] = useState(false);

    const toggleShowPersonaPopup = () => {
        navigate('my_bunch');
    };
    const toggleBuyVotesPopup = () => {
        setShowBuyVotes(!showBuyVotes);
    };
    const toggleEditPopup = () => {
        setShowAddPost(!showAddPost);
    };

    const handleClickOutside = (event) => {
        if (buyVotesRef.current && !buyVotesRef.current.contains(event.target)) {
            setShowBuyVotes(false);
        }
        if (addPostRef.current && !addPostRef.current.contains(event.target)) {
            setShowAddPost(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='flex sm:hidden fixed bottom-0 w-full justify-between bg-white dark:bg-black'>
            <div className='cursor-pointer' onClick={toggleShowPersonaPopup}>
                <HoverIcon
                    defaultIconBlack={tieBlack}
                    defaultIconWhite={tieWhite}
                    activeIcon={tieBlue}
                    altText=""
                />
            </div>
            <div className=''>
                <div className='cursor-pointer flex' onClick={toggleBuyVotesPopup}>
                    <img src={gasBlack} alt="" className="lightMode" />
                    <img src={gasWhite} alt="" className="darkMode" />
                </div>
            </div>
            <div className='cursor-pointer' onClick={toggleEditPopup}>
                <HoverIcon
                    defaultIconBlack={pencilBlack}
                    defaultIconWhite={pencilWhite}
                    activeIcon={pencilBlue}
                    altText=""
                />
            </div>

            {showBuyVotes &&
                <div ref={buyVotesRef} className="absolute right-0 bottom-24 w-full flex justify-between items-center p-[10px_15px_10px_5px] bg-[#E5E5EF] dark:bg-[#000] dark:border">
                    <h4 className="font-700">87 Votes remain</h4>
                    <button className="btnBlue w-[73px]">Buy more</button>
                </div>}

            {showAddPost &&
                <div ref={addPostRef} className='absolute right-0 z-10 w-full bottom-[42px]' >
                    <AddPost />
                </div>
            }
        </div>
    );
}

export default MobileFooter;
