import React, { useState, useEffect, useContext } from 'react';
import InTheNews from '../components/InTheNews';
import Post from '../components/Post.js';

import { usePost } from '../components/context/PersonaPostContext/useGlobal';
import { GlobalContext } from '../components/context/PersonaPostContext/GlobalContext';

function HomeNew() {
  const { postDetails, loading: loadingPost, error: errorPost } = usePost();
  const { setPostId } = useContext(GlobalContext);

  useEffect(() => {
    setPostId(0);
  }, [setPostId]);

  return (
    <div className="">
      <div className='min-h-screen'>
        <div className="grid grid-cols-12">
          <div className="ColClass1">
          </div>

          <div className="ColClass2">
            <InTheNews />
          </div>

          <div className="ColClass3">
            <div className='flex flex-col gap-[5px] lg:gap-[10px]'>
              {postDetails.map((post, index) => (
                <Post key={index} post={post} />
              ))}
            </div>
          </div>

          <div className="ColClass4">
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeNew;
