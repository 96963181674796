import React, { useState, useEffect, useContext } from 'react';
import Persona from './../components/Persona';
import Post from '../components/Post.js';
import { usePersona, usePost } from '../components/context/PersonaPostContext/useGlobal';
import { GlobalContext } from '../components/context/PersonaPostContext/GlobalContext';
import InTheNews from '../components/InTheNews';

function FilterPost() {
  const { personaDetails, loading: loadingPersona, error: errorPersona } = usePersona();
  const { setPersonaId } = useContext(GlobalContext);

  const { postDetails, loading: loadingPost, error: errorPost } = usePost();
  const { setPostId } = useContext(GlobalContext);
  
  useEffect(() => {
    setPersonaId(0);
  }, [setPersonaId]);

  useEffect(() => {
    setPostId(0);
  }, [setPostId]);

  const filteredPosts = postDetails.filter((post) => post.no_of_comments > 0);

  return (
    <div className="">
      <div className='min-h-screen'>
        <div className="grid grid-cols-12">
          <div className="ColClass1">
          </div>

          <div className="ColClass2">
            <InTheNews />
          </div>

          <div className="ColClass3">
            <div className='flex flex-col gap-[5px] lg:gap-[10px]'>
              {filteredPosts.map((post, index) => (
                <Post key={index} post={post} />
              ))}
            </div>
          </div>

          <div className="ColClass4">
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterPost;
