import React, { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login";

const FacebookAuth = () => {
  const [sdkLoaded, setSdkLoaded] = useState(false);

  useEffect(() => {
    if (window.FB) {
      setSdkLoaded(true);
      return;
    }

    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "1190611449436490", // Replace with your Facebook App ID
        cookie: true,
        xfbml: true,
        version: "v18.0",
      });
      setSdkLoaded(true);
    };

    if (!document.getElementById("facebook-jssdk")) {
      let js = document.createElement("script");
      js.id = "facebook-jssdk";
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      document.body.appendChild(js);
    }
  }, []);

  const responseFacebook = (response) => {
    console.log("Facebook Login Response:", response);
  };

  return (
    <div>
      {sdkLoaded ? (
        <FacebookLogin
          appId="1190611449436490"
          autoLoad={false}
          fields="name,email,picture"
          callback={responseFacebook}
          icon="fa-facebook"
        />
      ) : (
        <p>Loading Facebook SDK...</p>
      )}
    </div>
  );
};

export default FacebookAuth;