import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../components/context/AuthContext/AuthContext";

import profilePic from '../asset/profilePic.png';
import commentBlack from '../asset/post/comment_black.svg';
import commentWhite from '../asset/post/comment_white.svg';
import intelligent from '../asset/smallGraph/intelligent.svg';
import stupid from '../asset/smallGraph/stupid.svg';
import helpless from '../asset/smallGraph/helpless.svg';
import bandit from '../asset/smallGraph/bandit.svg';
import ineffective from '../asset/smallGraph/ineffective.svg';

function Post({ post }) {
  const setBoxShadow = (value) => {
    switch (value) {
      case 'intelligent':
        return 'postCard post intelligentBorder';
      case 'stupid':
        return 'postCard post stupidBorder';
      case 'helpless':
        return 'postCard post helplessBorder';
      case 'bandit':
        return 'postCard post banditBorder';
      default:
        return 'postCard post neutralBorder';
    }
  };
  
  const navigate = useNavigate();
  const handleNavigation = () => {
    const url = post.post_name.replace(/ /g, "_").toLowerCase();
    const id = post.post_unique_id;
    navigate(`/post_details`, { state: { id } });
  };

  return (
    <div className={setBoxShadow(post.vote_type)}>
      <div className='flex justify-end items-center gap-1'>
        <div>
          {(post.user_name == null || post.user_name == '') &&
            <h5 className='font-400 text-right'>anonymous</h5>
          }
          {(post.user_name != null || post.user_name != '') &&
            <h5 className='font-400 text-right'>{post.user_name}</h5>
          }
          <h6 className='font-100 text-right'>{post.time_ago}</h6>
        </div>
        <div>
          {post.user_image ?
            <img src={`${process.env.REACT_APP_IMAGE_URL}${post.user_image}`} alt="" />
            :
            <img src={profilePic} alt="" />
          }
        </div>
      </div>
      <div onClick={handleNavigation}>
        <div className='flex items-center gap-[10px]'>
          <img src={`${process.env.REACT_APP_IMAGE_URL}${post.post_image}`} alt="..." className='w-[30px] h-[40px]' />
          <h3 className='font-400'>{post.post_name}</h3>
        </div>
        <div className='flex justify-between items-center'>
          <div className='flex items-center gap-1'>
            <div>
              <img src={commentWhite} className='darkMode' />
              <img src={commentBlack} className='lightMode' />
            </div>
            <h6 className='font-100'>{post.no_of_comments} comments</h6>
          </div>
          <div className='flex items-center gap-1'>
            <h6 className='font-100'>{post.no_of_votes} votes</h6>
            {post.vote_type == 'intelligent' &&
              <img src={intelligent} alt="" />
            }
            {post.vote_type == 'stupid' &&
              <img src={stupid} alt="" />
            }
            {post.vote_type == 'helpless' &&
              <img src={helpless} alt="" />
            }
            {post.vote_type == 'bandit' &&
              <img src={bandit} alt="" />
            }
            {post.vote_type == 'ineffective' &&
              <img src={ineffective} alt="" />
            }
            {post.vote_type == 'unrated' &&
              <img src={ineffective} alt="" />
            }
            {/* <BubbleBlue data={post.data} /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Post;
