import React, { useState, useEffect } from 'react';
import axiosInstance from "../utils/axiosInstance";
import { useVariable } from './context/VariableContext/VariableContext';

import searchBlack from '../asset/search_black.svg';
import searchWhite from '../asset/search_white.svg';
import sendBlack from '../asset/comments/send_black.svg';
import sendWhite from '../asset/comments/send_white.svg';

function AddPost() {
    const { state } = useVariable();

    const [personaDetails, setPersonaDetails] = useState([]);
    const [postValues, setPostValues] = useState({
        // postName: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
        // postUrl: 'https://www.lipsum.com/',
        postName: '',
        postUrl: '',
    });
    const [selectedOption, setSelectedOption] = useState([]);

    const getPersonaDetails = async () => {
        let prmData = {
            personaUniqueId: 0,
        };
        try {
            const response = await axiosInstance.post('common/personaSelect', prmData);
            setPersonaDetails(response.data);
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    const addPostDetails = async () => {
        let prmData = {
            typeOfInsertTagUniqueId: state.postInsert,
            personaUniqueId: selectedOption.persona_unique_id,
            postImage: selectedOption.persona_image,
            postName: postValues.postName,
            postUrl: postValues.postUrl,
        };
        try {
            const response = await axiosInstance.post('userAction/userActionInsert', prmData);
            alert("Successfully added");
            setPostValues({
                personaUniqueId: '',
                postName: '',
            });
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    const handleChange = (event) => {
        const selectedValue = event.target.value;
        const selectedObject = personaDetails.find((option) => option.persona_unique_id == selectedValue);
        setSelectedOption(selectedObject);
    };

    const handlePostInput = (event) => {
        const { name, value } = event.target;
        setPostValues({
            ...postValues,
            [name]: value
        });
    };

    useEffect(() => {
        getPersonaDetails();
    }, []);

    return (
        <div>
            <div className="registrationCard">
                <div className="relative">
                    <input type="text" className="inputField !pl-[25px] w-full" placeholder="Whom?" />
                    <div className="absolute top-[4px] left-[5px]">
                        <img src={searchWhite} alt="" className="darkMode" />
                        <img src={searchBlack} alt="" className="lightMode" />
                    </div>
                </div>
                <select
                    id="persona_unique_id"
                    name='persona_unique_id'
                    value={selectedOption}
                    onChange={handleChange}
                >
                    <option value="" disabled>
                        Select Persona
                    </option>
                    {personaDetails.map((option) => (
                        <option key={option.persona_unique_id} value={option.persona_unique_id}>
                            {option.persona_name}
                        </option>
                    ))}
                </select>
                <input type="text" className="inputField" placeholder="Post title max 250 chars" name="postName" value={postValues.postName} onChange={handlePostInput} />
                <input type="text" className="inputField" placeholder="URL" name="postUrl" value={postValues.postUrl} onChange={handlePostInput} />

                <div className="flex justify-between items-center" onClick={addPostDetails}>
                    <h5 className="font-300"></h5>
                    <img src={sendWhite} alt="" className="darkMode" />
                    <img src={sendBlack} alt="" className="lightMode" />
                </div>
            </div>
        </div>
    );
}

export default AddPost;
