import React, { useState, useEffect, useContext } from 'react';
import { usePersona } from '../components/context/PersonaPostContext/useGlobal';
import { GlobalContext } from '../components/context/PersonaPostContext/GlobalContext';
import Persona from "./Persona";

import tieBlack from '../asset/tie_black.svg';
import tieWhite from '../asset/tie_white.svg';

function InTheNews() {
    const { personaDetails, loading: loadingPersona, error: errorPersona } = usePersona();
    const { setPersonaId } = useContext(GlobalContext);

    useEffect(() => {
        setPersonaId(0);
    }, [setPersonaId]);

    return (
        <div className='leftColHeader'>
            <div className='flex items-center justify-center w-fit m-auto border-b-[2px] border-[#0000001A] dark:border-[#FFFFFF40] mb-[10px]'>
                <div>
                    <img src={tieWhite} alt="" className="darkMode" />
                    <img src={tieBlack} alt="" className="lightMode" />
                </div>
                <div>
                    <h2 className='font-400'>In the news today</h2>
                </div>
            </div>
            <div className='flex flex-col gap-[5px] xl:gap-[10px]'>
                {personaDetails.map((persona, index) => (
                    <Persona key={index} persona={persona} />
                ))}
            </div>
        </div>
    );
}

export default InTheNews;
