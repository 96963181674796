import React, { useState } from 'react';
import axiosInstance from "../../utils/axiosInstance";
import { useAuth } from '../context/AuthContext/AuthContext';

import sendBlack from '../../asset/comments/send_black.svg';
import sendWhite from '../../asset/comments/send_white.svg';
import eyeOn from '../../asset/login/eye_on.svg';
import eyeOff from '../../asset/login/eye_off.svg';

function Login({ closePopup }) {
    const [email, setEmail] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const { login, isAuthenticated } = useAuth();

    const loginFn = async () => {
        let prmData = {
            email: email,
            password: password,
        };
        try {
            const response = await axiosInstance.post('user/userLogin', prmData);
            const token = response.data.token;
            localStorage.setItem('token', token);
            const userResponse = await axiosInstance.get('user/userData');
            const userDetails = userResponse.data;
            login(token, userDetails);
            closePopup();
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    const togglePassword = () => {
        setShowPassword(prevState => !prevState)
    }

    return (
        <div className="registrationCard">
            <input
                type="text"
                className="inputField"
                placeholder="User ID"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <div className='relative'>
                <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    className="inputField w-full"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button type="button" className="absolute right-3 top-[2px] text-gray-600" onClick={togglePassword}>
                    {showPassword ? <img src={eyeOn} alt="" /> : <img src={eyeOff} alt="" />}
                </button>
            </div>
            <div className="flex justify-between items-center">
                <h5 className="font-300"></h5>
                <div onClick={loginFn}>
                    <img src={sendWhite} alt="" className="darkMode" />
                    <img src={sendBlack} alt="" className="lightMode" />
                </div>
            </div>
            {isAuthenticated && <p>Logged in successfully</p>}
        </div>
    );
}

export default Login;
