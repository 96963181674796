import React, { useState, useEffect, useContext } from 'react';
import Persona from "../components/Persona";
import Post from '../components/Post.js';

import { usePersona, usePost } from '../components/context/PersonaPostContext/useGlobal';
import { GlobalContext } from '../components/context/PersonaPostContext/GlobalContext';
import { useMixin } from '../components/context/MixinContext/useMixin';
import { useVariable } from '../components/context/VariableContext/VariableContext';

function Home() {
  const { personaDetails, loading: loadingPersona, error: errorPersona } = usePersona();
  const { postDetails, loading: loadingPost, error: errorPost } = usePost();
  const { setPersonaId, setPostId } = useContext(GlobalContext);
  const { showPersona, setShowPersona } = useMixin();
  const { state, updateState } = useVariable();

  useEffect(() => {
    setPersonaId(0);
    setPostId(0);
  }, [setPersonaId, setPostId]);

  return (
    <div className="">
      <div className='flex justify-center items-start min-h-screen gap-2 lg:gap-10'>
        <div className='w-full md:w-1/4 flex flex-col gap-1 lg:gap-[10px] forDesktop'>
          {personaDetails.length > 0 ? (
            personaDetails.map((persona, index) => (
              <Persona key={index} persona={persona} />
            ))
          ) : (
            <div>No personas available</div>
          )}
        </div>
        <div className='h-screen border border-[#0000001A] forDesktop'></div>
        <div className='w-full flex flex-col gap-[5px] lg:gap-[10px]'>
          {postDetails.length > 0 ? (
            postDetails.map((post, index) => (
              <Post key={index} post={post} />
            ))
          ) : (
            <div>No posts available</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
