import React, { useState, useEffect } from 'react';
import axiosInstance from "../../utils/axiosInstance";

import sendBlack from '../../asset/comments/send_black.svg';
import sendWhite from '../../asset/comments/send_white.svg';
import eyeOn from '../../asset/login/eye_on.svg';
import eyeOff from '../../asset/login/eye_off.svg';

function Signup({ showStepTwo }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const signUpFn = async () => {
        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match!');
        } else {
            setErrorMessage('');
            let prmData = {
                email: email,
                password: password,
            };
            try {
                const response = await axiosInstance.post('user/signup', prmData);
                let data = response.data[0];
                if (data.user_id > 0) {
                    localStorage.setItem('userID', data.user_id);
                    localStorage.setItem('emailStorage', email);
                    localStorage.setItem('passwordStorage', password);
                    if (data.returntype) {
                        setEmail("");
                        setPassword("");
                        setConfirmPassword("");
                    }
                    showStepTwo();
                }
                else {
                    alert("Email already exists");
                }
            } catch (error) {
                console.error('There was an error making the request!', error);
            }
        }
    };

    const togglePassword = () => {
        setShowPassword(prevState => !prevState)
    }

    const toggleConfirmPassword = () => {
        setShowConfirmPassword(prevState => !prevState)
    }

    return (
        <div className="registrationCard">
            <input type="text" className="inputField" placeholder="Email ID" onChange={(e) => setEmail(e.target.value)} />
            <div className='relative'>
                <input
                    type={showPassword ? "text" : "password"}
                    className="inputField w-full"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button type="button" className="absolute right-3 top-[2px] text-gray-600" onClick={togglePassword}>
                    {showPassword ? <img src={eyeOn} alt="" /> : <img src={eyeOff} alt="" />}
                </button>
            </div>
            <div className='relative'>
                <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="inputField w-full"
                    placeholder="Confirm Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button type="button" className="absolute right-3 top-[2px] text-gray-600" onClick={toggleConfirmPassword}>
                    {showConfirmPassword ? <img src={eyeOn} alt="" /> : <img src={eyeOff} alt="" />}
                </button>
            </div>
            <h4>by registering you agree to the terms and conditions</h4>

            <div className="flex justify-between items-center">
                <h5 className="font-300 cursor-pointer"></h5>
                <div onClick={signUpFn}>
                    <img src={sendWhite} alt="" className="darkMode" />
                    <img src={sendBlack} alt="" className="lightMode" />
                </div>
            </div>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
    );
}

export default Signup;
